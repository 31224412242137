import React from 'react';
import './footer.css'
import GitHub from "../../assets/social-icons/GitHub.png"
import LinkedIn from "../../assets/social-icons/Linkedin.png"
import Telegram from "../../assets/social-icons/Telegram.png"
import Instagram from "../../assets/social-icons/Instagram.png"
import Facebook from "../../assets/social-icons/Facebook.png"
import footer from './footer.png';

function Footer() {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="title">
                    Stacks<span className="logo-span">.ps</span>
                </div>
                <div className="footer-email">team@oppotrain.com</div>
                <div className="social-icons-bar">
                    <img className="icon" src={GitHub} alt="GitHub-logo"/>
                    <img className="icon" src={LinkedIn} alt="LinkedIn-logo"/>
                    <img className="icon" src={Telegram} alt="Telegram-logo"/>
                    <img className="icon" src={Facebook} alt="facebock-logo"/>
                    <img className="icon" src={Instagram} alt="Instagram-logo"/>
                </div>
                <div className="copyright">©2025 Oppotrain LLC</div>
            </div>
        </div>);
}

export default Footer;
